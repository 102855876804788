import { DocumentConst } from "constants/document/DocumentConst";
import { CriteriaDirectoryDocumentCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryCriteriaCheckboxRelatedField as checkboxRelatedFields } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteriaCheckboxRelatedField";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";
export const DocumentEnquiryCriteriaPreviewDocumentPanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useCallback(() =>
        CriteriaDirectoryDocumentCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]),
        [docEnquiryState.enabledSearchCriteria])

    const isAllDefaultValue = useCallback(() => {
        const attributeField = CriteriaDirectoryDocumentCheckboxOption?.filter(item => docEnquiryState.enabledSearchCriteria[item.key])?.map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, field)
        })
        return isAllDefault
    }, [searchCriteria, docEnquiryState.enabledSearchCriteria])

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        docEnquiryVM.showLoading();
        docEnquiryVM.onRemoveSearchCriteria(fieldName, isSearch);
        docEnquiryVM.hideLoading();
    }, [docEnquiryVM]);
    const docEnqConstant = DocumentEnquiryConstant;

    const memoPanel = useMemo(() => <>
        {(enabledCriteria() && !isAllDefaultValue()) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Document' />
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'invDate') && <Tags
                    label={`${docEnqConstant.Header.INVOICE_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.invDateFrom)} - ${convertDateToDateForComponent(searchCriteria.invDateTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('invDate')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'invNo') && <Tags
                    label={`${docEnqConstant.Header.INVOICE_NO} : ${searchCriteria.invNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('invNo')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'oriDocNo') && <Tags
                    label={`${docEnqConstant.Header.ORI_DOC_NO} : ${searchCriteria.oriDocNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('oriDocNo')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'previewRefNo') && <Tags
                    label={`${docEnqConstant.Header.PREVIEW_REF_NO}: ${searchCriteria.previewRefNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('previewRefNo')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'stateList') && <Tags
                    label={`${docEnqConstant.Header.STATE}  : ${searchCriteria.stateList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('stateList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'docTypeList') && <Tags
                    label={`${docEnqConstant.Header.DOC_TYPE}  : ${searchCriteria.docTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('docTypeList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'publicAtWebSiteList') && <Tags
                    label={`${docEnqConstant.Header.PUB_WEB_SITE}  : ${searchCriteria.publicAtWebSiteList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('publicAtWebSiteList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'issueByEdiList') && <Tags
                    label={`${docEnqConstant.Header.EXP_TYPE}  : ${searchCriteria.issueByEdiList?.map(key => DocumentConst.ediExportType[key as keyof typeof DocumentConst.ediExportType]).join(',')}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('issueByEdiList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'genDate') && <Tags
                    label={`${docEnqConstant.Header.GEN_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.genDateFrom)} - ${convertDateToDateForComponent(searchCriteria.genDateTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('genDate')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'generatedBy') && <Tags
                    label={`${docEnqConstant.Header.GEN_BY}: ${searchCriteria.generatedBy}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('generatedBy')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'issuedBy') && <Tags
                    label={`${docEnqConstant.Header.ISSUED_BY}: ${searchCriteria.issuedBy}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('issuedBy')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'transactionType') && <Tags
                    label={`${docEnqConstant.Header.TRANSACTION_TYPE}: ${searchCriteria.transactionType}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('transactionType')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'taxCodeList') && <Tags
                    label={`${docEnqConstant.Header.TAX_CODE}  : ${searchCriteria.taxCodeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('taxCodeList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'taxDate') && <Tags
                    label={`${docEnqConstant.Header.TAX_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.taxDateFrom)} - ${convertDateToDateForComponent(searchCriteria.taxDateTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('taxDate')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'taxPaymentTerm') && <Tags
                    label={`${docEnqConstant.Header.TAX_PAYMENT_TERM}: ${searchCriteria.taxPaymentTerm}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('taxPaymentTerm')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'approvalStatusList') && <Tags
                    label={`${docEnqConstant.Header.APPR_STATUS}  : ${searchCriteria.approvalStatusList?.map(key => DocumentConst.docuemtApprovalStatus[key as keyof typeof DocumentConst.docuemtApprovalStatus]).join(',')}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('approvalStatusList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'approvalDatetime') && <Tags
                    label={`${docEnqConstant.Header.APPR_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.approvalDatetimeFrom)} - ${convertDateToDateForComponent(searchCriteria.approvalDatetimeTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('approvalDatetime')}
                />}
            </div>
        </div> : null}</>
        , [docEnqConstant.Header.APPR_DATE_RANGE, docEnqConstant.Header.APPR_STATUS, docEnqConstant.Header.DOC_TYPE, docEnqConstant.Header.EXP_TYPE, docEnqConstant.Header.GEN_BY,
        docEnqConstant.Header.GEN_DATE_RANGE, docEnqConstant.Header.INVOICE_DATE_RANGE, docEnqConstant.Header.INVOICE_NO, docEnqConstant.Header.ISSUED_BY,
        docEnqConstant.Header.ORI_DOC_NO, docEnqConstant.Header.PREVIEW_REF_NO, docEnqConstant.Header.PUB_WEB_SITE, docEnqConstant.Header.STATE, docEnqConstant.Header.TAX_CODE,
        docEnqConstant.Header.TAX_DATE_RANGE, docEnqConstant.Header.TAX_PAYMENT_TERM, docEnqConstant.Header.TRANSACTION_TYPE, enabledCriteria, handleRemoveSearchCriteira,
            isAllDefaultValue, searchCriteria]);

    return memoPanel;
};
